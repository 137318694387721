<template>
  <div>
    <Header />
    <div class="md:h-480 h-96 w-full bg-black bg-opacity-30 relative">
      <img
        src="../assets/hero-about.png"
        alt="Hero About"
        class="h-full w-full absolute object-cover mix-blend-overlay -z-10"
      />
      <div
        class="h-full w-full flex justify-center items-center text-center text-white font-bold"
      >
        <h1 class="md:text-7xl text-4xl">Privacy Policy</h1>
      </div>
    </div>
    <div class="md:mx-20 md:my-16 p-4">
      <div class="flex flex-col gap-8">
        <h1 class="font-bold md:text-5xl text-2xl">TRIPWE</h1>
        <div class="flex flex-col gap-4">
          <p class="font-semibold md:text-2xl text-lg text-center">
            KEBIJAKAN PRIVASI
          </p>
          <p class="text-justify text-sm md:text-base">
            Kebijakan Privasi ini merupakan bagian dari Perjanjian Pengguna
            Aplikasi Tripwe(“<strong>Perjanjian Pengguna</strong>”) Tripwe.
            Penggunaan Aplikasi Tripwe dan/atau Situs Tripwe dan setiap fitur
            dan/atau Layanan yang tersedia merupakan bentuk persetujuan Pengguna
            terhadap Perjanjian Pengguna dan Kebijakan Privasi ini. Oleh karena
            itu, Pengguna perlu untuk membaca Kebijakan Privasi ini secara
            saksama untuk memastikan bahwa Pengguna memahaminya sepenuhnya
            sebelum mendaftar, mengakses dan/atau menggunakan Aplikasi Tripwe
            dan/atau Situs Tripwe maupun Layanan Tripwe.
          </p>
          <ul
            class="list-decimal text-justify text-sm md:text-base md:ml-10 ml-4"
          >
            <li>
              Dengan menggunakan Situs Tripwe dan/atau Aplikasi Tripwe, maka
              Pengguna dianggap telah membaca Kebijakan Privasi ini.
            </li>
            <li>
              “<strong>Data Pribadi</strong>” mencakup semua data, informasi,
              dan/atau keterangan dalam bentuk apapun yang dapat
              mengidentifikasikan diri Pengguna, antara lain: nama lengkap,
              kartu identitas dan termasuk semua data yang ada didalamnya,
              paspor dan seluruh datanya, tanggal lahir, jenis kelamin, status
              perkawinan, tinggi/ berat badan, golongan darah dan rhesus darah,
              alamat surat elektronik (e-mail), nomor handphone – termasuk namun
              tidak terbatas pada – IP address, informasi lokasi, menyimpan
              lokasi Pengguna, menampilkan lokasi Pengguna, data perangkat
              Pengguna, kontak number di smartphone, nomor IMEI, dan termasuk
              menyetujui pemberian data tersebut ke aplikasi-aplikasi lain yang
              Tripwe buat yang telah dilekatkan dan atau dihubungkan dalam
              Aplikasi Tripwe dan/atau Situs Tripwe yang Pengguna pakai pada
              perangkat Pengguna, data yang menyangkut informasi mengenai
              kegiatan transaksi Pengguna pada Aplikasi Tripwe dan/atau Situs
              Tripwe, dan data lainnya yang tergolong sebagai data pribadi baik
              pada saat Pengguna mulai menggunakan Aplikasi Tripwe dan/atau
              Situs Tripwe maupun waktu-waktu seterusnya selama Pengguna
              menggunakan Aplikasi Tripwe dan/atau Situs Tripwe ini.
            </li>
            <li>
              Tripwe mengumpulkan Data Pribadi Pengguna dengan tujuan untuk
              memproses transaksi Pengguna, mengelola dan memperlancar proses
              penggunaan aplikasi, serta tujuan-tujuan lainnya selama diizinkan
              oleh peraturan perundang-undangan
            </li>
            <li>
              Tripwe menggunakan Data Pribadi yang diperoleh dan dikumpulkan
              dari Pengguna untuk tujuan hal-hal sebagai berikut dan tujuan lain
              yang diperbolehkan oleh peraturan perundang-undangan yang berlaku:
              <ol class="md:ml-10 ml-8">
                <li>
                  A. untuk mengidentifikasi dan mendaftarkan Pengguna sebagai
                  pengguna dan untuk mengurus, memverifikasi, menonaktifkan,
                  atau mengelola akun Anda seperti itu
                </li>
                <li>
                  B. untuk memproses segala bentuk permintaan, aktivitas,
                  pembayaran maupun transaksi yang dilakukan oleh Pengguna
                  melalui Aplikasi Tripwe dan/atau Situs Tripwe.
                </li>
                <li>
                  C. untuk membantu pengguna pada saat berkomunikasi dengan
                  Vendor.
                </li>
                <li>
                  D. untuk melakukan pengawasan dan/atau melakukan investigasi
                  terhadap transaksi mencurigakan.
                </li>
                <li>
                  E. untuk menawarkan atau menyediakan layanan dari afiliasi
                  atau mitra Tripwe.
                </li>
                <li>
                  F. untuk memungkinkan penyedia layanan menyediakan Pengguna
                  dengan layanan seperti yang diminta.
                </li>
                <li>
                  G. untuk memantau dan menganalisis aktivitas pengguna,
                  perilaku, dan data demografis termasuk tren dan penggunaan
                  berbagai layanan yang tersedia di Aplikasi Tripwe
                </li>
                <li>
                  H. untuk mengirimkan pesan pemasaran secara langsung ataupun
                  yang ditujukkan, iklan, voucher, survei, dan informasi
                  sehubungan dengan penawaran khusus ataupun promosi.
                </li>
                <li>
                  I. dalam keadaan tertentu, Tripwe mungkin perlu untuk
                  mengggunakan atau mengungkap Data Pribadi Pengguna untuk
                  tujuan penegakkan hukum dalam hal terjadi penipuan atau
                  pencurian data.
                </li>
              </ol>
            </li>
            <li>
              Tripwe berupaya memastikan bahwa informasi yang Pengguna berikan
              kepada Tripwe aman dan tidak dapat dipergunakan oleh pihak-pihak
              yang tidak bertanggung jawab. Untuk keamanan data Pengguna, Tripwe
              sangat menyarankan agar Pengguna selalu memperbaharui aplikasi dan
              perangkat lunak serta tidak mengungkapkan kata sandi Pengguna
              kepada pihak apapun.
            </li>
            <li>
              Pengguna dengan ini setuju bahwa Tripwe dapat menyimpan Data
              Pribadi pada server yang terletak di pusat data yang ditunjuk oleh
              Tripwe. Pemanfaatan Data Pribadi sehubungan dengan penggunaan
              Aplikasi Tripwe dan/atau Situs Tripwe akan terus diatur oleh
              Kebijakan Privasi ini sesuai dengan peraturan perundangan-undangan
              yang berlaku di Republik Indonesia.
            </li>
            <li>
              Kebijakan Privasi ini diatur berdasarkan hukum Negara Republik
              Indonesia dan Pengguna diwajibkan tunduk kepada semua peraturan
              yang berlaku di Republik Indonesia.
            </li>
          </ul>
        </div>
        <div class="flex flex-col gap-4">
          <p class="font-semibold md:text-2xl text-lg text-center">
            KEAKURATAN DATA PRIBADI
          </p>
          <ul
            class="list-decimal text-justify text-sm md:text-base md:ml-10 ml-4"
          >
            <li>
              Tripwe membutuhkan Data Pribadi Pengguna untuk salah satunya dapat
              melakukan pemrosesan transaksi, maka Data Pribadi yang Pengguna
              berikan kepada Tripwe haruslah seakurat mungkin dan tidak
              menyesatkan. Pengguna harus memperbaharui dan memberitahukan
              kepada Tripwe apabila ada perubahan terhadap Data Pribadi
              Pengguna. Dengan menggunakan Aplikasi Tripwe dan/atau Situs
              Tripwe, berarti Pengguna membebaskan Tripwe dari setiap tuntutan,
              gugatan, ganti rugi, dan/atau klaim sehubungan dengan kegagalan
              pemrosesan transaksi pada Aplikasi Tripwe dan/atau Situs Tripwe
              yang disebabkan oleh ketidakakuratan Data Pribadi yang Pengguna
              berikan kepada Tripwe.
            </li>
            <li>
              Apabila Pengguna belum berusia 18 tahun, belum menikah atau berada
              di bawah perwalian atau pengampuan maka Pengguna memerlukan
              persetujuan dari orang tua, wali atau pengampu Pengguna yang sah
              untuk memberikan Data Pribadi kepada Tripwe. Saat Data Pribadi
              Pengguna tersebut diberikan kepada Tripwe, Pengguna dengan ini
              menyatakan dan menjamin bahwa orang tua, wali yang sah atau
              pengampu Pengguna telah menyetujui pemrosesan Data Pribadi
              Pengguna tersebut dan secara pribadi menerima dan setuju untuk
              terikat oleh Kebijakan Privasi ini dan bertanggung jawab atas
              tindakan Pengguna
            </li>
            <li>
              Saat Pengguna memberikan Data Pribadi yang berkaitan dengan orang
              lain, misalnya pasangan, anggota keluarga, dan/atau teman
              Pengguna, berarti pula Pengguna menyatakan dan menjamin bahwa
              Pengguna telah memiliki wewenang dan persetujuan dari pemilik Data
              Pribadi tersebut untuk memberikan Data Pribadi mereka kepada
              Tripwe untuk digunakan sesuai dengan tujuan-tujuan yang diuraikan
              dalam Kebijakan Privasi ini.
            </li>
          </ul>
        </div>
        <div class="flex flex-col gap-4">
          <p class="font-semibold md:text-2xl text-lg text-center">
            PILIHAN PENGGUNA DAN TRANSAPARANSI
          </p>
          <ul
            class="list-decimal text-justify text-sm md:text-base md:ml-10 ml-4"
          >
            <li>
              Perangkat seluler pada umumnya memiliki pengaturan sehingga
              Aplikasi Tripwe maupun Situs Tripwe tidak dapat mengakses data
              tertentu tanpa persetujuan dari Pengguna.
            </li>
            <li>
              Pengguna dapat mengakses dan mengubah informasi berupa alamat
              e-mail, nomor telepom, tanggal lahir, jenis kelamin, daftar
              alamat, metode pembayayaran, dan rekening bank melalui fitur
              pengaturan
            </li>
          </ul>
        </div>
        <div class="flex flex-col gap-4">
          <p class="font-semibold md:text-2xl text-lg text-center">
            PENGUMPULAN DATA PRIBADI
          </p>
          <ul
            class="list-decimal text-justify text-sm md:text-base md:ml-10 ml-4"
          >
            <li>
              Penyediaan Data Pribadi Pengguna bersifat sukarela. Namun, bila
              Pengguna tidak memberikan Data Pribadinya kepada Tripwe, Tripwe
              tidak dapat memberikan layanan-layanan atau produk- produk atau
              memproses pembayaran-pembayaran dari Pengguna
            </li>
            <li>
              Pengumpulan Data Pribadi Pengguna terjadi saat Pengguna membuat
              atau melakukan pengkinian Akun atau pada saat lainnya apabila
              dibutuhkan dari waktu ke waktu. Tripwe akan mengumpulkan Data
              Pribadi Pengguna setiap kali Pengguna mengakses atau menggunakan
              Aplikasi Tripwe dan/atau Situs Tripwe.
            </li>
            <li>
              Apabila Pengguna menggunakan dan/atau pada saat pembayaran ataupun
              transfer dilakukan melalui uang elektronik dan/atau dompet digital
              yang disediakan oleh Tripwe, kami dapat mungumpulkan informasi
              tertentu terkait terkait sumber dana anda untuk isi ulang
              (termasuk rincian rekening bank ), rincian akun penerima
              penarikan, riwayat transaksi (termasuk rincian penerima), rincian
              tagihan, rincian faktur, dan nomor telepon.
            </li>
            <li>
              Apabila Pengguna menggunakan virtual akun yang disediakan oleh
              Tripwe untuk menerima uang elektronik dan/atau dompet digital dari
              pembayar, baik Pengguna adalah penyedia layanan ataupun merchant,
              kami dapat mengumpulkan informasi tertentu sehubungan dengan
              penggunaan anda termasuk namun tidak terbatas pada layanan
              dan/atau barang yang ditransaksikan, jumlah yang dikumpulkan
              Pengguna dari setiap transaksi, penarikan atau rincian rekening
              penyelesaian dan penarikan atau riwayat penyelesaian.
            </li>
            <li>
              Apabila Pengguna menggunakan dan/atau pada saat pembayaran terjadi
              melalui kartu atau akun yang ditambahkan Pengguna kedalam Aplikasi
              Tripwe, Tripwe dapat mengumpulkan informasi tertentu sehubungan
              dengan riwayat transaksi (termasuk rincian penerimaan), rincian
              tagihan, rincia faktur, dan nomor telephone.
            </li>
          </ul>
        </div>
        <div class="flex flex-col gap-4">
          <p class="font-semibold md:text-2xl text-lg text-center">
            PENGGUNAAN DATA PRIBADI
          </p>
          <p class="text-justify text-sm md:text-base">
            Adapun tujuan Tripwe mengolah, menganalisis, dan/atau menggunakan
            Data Pribadi Pengguna sesuai dan diizinkan oleh peraturan
            perundang-undangan yang berlaku, adalah sebagai berikut:
          </p>
        </div>
        <div class="flex flex-col gap-4">
          <p class="font-semibold md:text-2xl text-lg text-center">
            TAUTAN KE SITUS PIHAK KETIGA
          </p>
          <p class="text-justify text-sm md:text-base">
            Dengan menggunakan Aplikasi Tripwe dan/atau Situs Tripwe ini,
            Pengguna telah memberikan ijin kepada Tripwe untuk melakukan tautan
            ke situs pihak ketiga. Tripwe tidak bertanggungjawab atas perolehan,
            pengumpulan, pengolahan, penganalisisan, penggunaan, penyimpanan,
            penampilan, pengumuman, pengiriman, penyebarluasan, penghapusan dan
            pemusnahan Data Pribadi Pengguna oleh pihak ketiga tersebut.
            Demikian pula bila Pengguna memberikan informasi langsung ke situs
            tersebut, kebijakan privasi dan ketentuan layanan di situs
            tersebutlah yang akan berlaku, dan Tripwe tidak bertanggung jawab
            atas kegiatan pengolahan informasi atau kebijakan privasi dari situs
            tersebut.
          </p>
        </div>
        <div class="flex flex-col gap-4">
          <p class="font-semibold md:text-2xl text-lg text-center">
            KEAMANAN, PENYIMPANAN DAN PENGHAPUSAN DATA PRIBADI PENGGUNA
          </p>
          <ul
            class="list-decimal text-justify text-sm md:text-base md:ml-10 ml-4"
          >
            <li>
              Tripwe melindungi setiap data pribadi yang disimpan dalam
              sistemnya serta melindungi data tersebut dari akses, penggunaan,
              modifikasi, pengambilan yang tidak sah, termasuk kata sandi dan
              kode OTP.
            </li>
            <li>
              Walaupun Tripwe sudah menggunakan upaya terbaiknya dalam menjaga
              data pribadi Pengguna, namun demikian, Tripwe tidak dapat menjamin
              100% keamanan data, dan menjadi risiko bagi Pengguna sendiri
            </li>
            <li>
              Setiap saat Pengguna menggunakan Aplikasi Tripwe atau mengunjungi
              Situs Tripwe, informasi tertentu juga dapat dikumpulkan secara
              otomatis menggunakan cookie. Cookie adalah file aplikasi kecil
              yang disimpan di komputer atau perangkat seluler Pengguna. Kami
              menggunakan cookie untuk melacak aktivitas pengguna guna
              meningkatkan antarmuka dan pengalaman pengguna. Sebagian besar
              perangkat seluler dan browser internet mendukung penggunaan
              cookie; namun Pengguna dapat menyesuaikan pengaturan pada
              perangkat seluler atau browser internet mereka untuk menolak
              beberapa jenis cookie tertentu atau cookie tertentu tertentu.
              Perangkat seluler dan/atau browser juga akan memungkinkan Pengguna
              untuk menghapus kapan saja cookie apa pun yang sebelumnya telah
              disimpan. Meskipun demikian, tindakan tersebut dapat memengaruhi
              fungsi yang tersedia di Aplikasi Tripwe atau Situs Tripwe.
            </li>
            <li>
              Tripwe akan menghapus atau menganonimkan data pribadi pengguna
              yang ada dibawah kendali Tripwe apabila data pribadi tersebut
              sudah tidak diperlukan untuk memenuhi tujuan pengumpulannya.
            </li>
          </ul>
        </div>
        <div class="flex flex-col gap-4">
          <p class="font-semibold md:text-2xl text-lg text-center">
            PENGADUAN TERKAIT PERLINDUNGAN DATA PENGGUNA
          </p>
          <ul
            class="list-decimal text-justify text-sm md:text-base md:ml-10 ml-4"
          >
            <li>
              Jika Pengguna memiliki kekhawatiran tentang penanganan atau
              perlakuan Tripwe terhadap data pribadi pengguna atau jika pengguna
              yakin bahwa privasinya telah dilanggar, Pengguna dapat menghubungi
              Tripwe melalui layanan Tripwe atau kepada kontak Tripwe yang
              tercantum dalam Kebijakan Privasi ini dengan menjelaskan identitas
              dan keluhan.
            </li>
            <li>
              Tripwe akan menyelidiki keluhan pengguna dan berupaya untuk
              memberikan tanggapan terhadap keluhan tersebut dalam kurun waktu
              yang wajar setelah menerima keluhan yang disampaikan oleh pengguna
            </li>
          </ul>
        </div>
        <div class="flex flex-col gap-4">
          <p class="font-semibold md:text-2xl text-lg text-center">
            PERUBAHAN TERHADAP KEBIJAKAN PRIVASI
          </p>
          <ul
            class="list-decimal text-justify text-sm md:text-base md:ml-10 ml-4"
          >
            <li>
              Tripwe dapat untuk setiap saat mengubah, memperbarui, dan/atau
              menambahkan sebagian ataupun seluruh ketentuan dalam Kebijakan
              Privasi ini, sesuai dengan bisnis Tripwe ke depan, dan/atau
              perubahan peraturan perundang-undangan yang berlaku. Bila
              perubahan atau penambahan tersebut Tripwe lakukan, Tripwe akan
              memberitahu Pengguna melalui notifikasi yang disampaikan melalui
              Aplikasi dan/atau situs https://www.Tripwe.com/, dan/atau ke
              alamat email Pengguna.
            </li>
            <li>
              Dengan terus menggunakan Aplikasi Tripwe maupun Situs Tripwe
              berarti Pengguna telah menyetujui perubahan, pembaharuan, dan/atau
              penambahan yang Tripwe lakukan terhadap Kebijakan Privasi ini.
            </li>
            <li>
              Jika Pengguna melanjutkan untuk menggunakan Aplikasi Tripwe
              dan/atau Situs Tripwe setelah membaca Kebijakan Privasi ini,
              Pengguna telah menyetujui Kebijakan Privasi ini – termasuk
              perubahannya – dan dapat melanjutkan penggunaan Aplikasi.
            </li>
          </ul>
        </div>
        <div class="flex flex-col gap-4">
          <p class="font-semibold md:text-2xl text-lg text-center">
            PENGALIHAN BISNIS
          </p>
          <p class="text-justify text-sm md:text-base">
            Dalam hal terjadi perubahan kendali atau perubahan kepemilikan atas
            semua atau sebagian dari Tripwe atau perusahaan, termasuk situs,
            maka Data Pribadi Pengguna mungkin/akan menjadi bagian dari
            pengalihan aset tersebut.
          </p>
        </div>
        <div class="flex flex-col gap-4">
          <p class="font-semibold md:text-2xl text-lg text-center">
            URUTAN PRIORITAS
          </p>
          <p class="text-justify text-sm md:text-base">
            Jika Pengguna telah menyetujui Perjanjian Pengguna kami, dalam hal
            terdapat perbedaan antara Perjanjian Pengguna tersebut dan Kebijakan
            Privasi ini terkait penggunaan data pribadi, maka Perjanjian
            Pengguna yang akan berlaku.
          </p>
        </div>
        <div class="flex flex-col gap-4">
          <p class="font-semibold md:text-2xl text-lg text-center">
            PENGALIHAN BISNIS
          </p>
          <p class="text-justify text-sm md:text-base">
            Dalam hal Pengguna memiliki pertanyaan, komentar atau klaim mengenai
            kebijakan privasi ini atau ingin mendapatkan akses atau koreksi
            terhadap data pribadinya, silahkan hubungi layanan pelanggan Tripwe
            melalui
            <a href="mailto:info@tripwe.com" class="text-green-1"
              >info@tripwe.com</a
            >
          </p>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";

export default {
  components: { Header, Footer },
  data: () => ({}),
};
</script>
